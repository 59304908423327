import React, { useState } from "react";
import styled from "styled-components";
import Logo from "./images/cescta_combologo.svg";
import { P } from "../CommonStyle";
import Button from "../Button";
import { userTrack } from "../Common";
import Image from "next/image";

const BandWrap = styled.div`
  background-color: #000;
  position: absolute;
  width: -webkit-fill-available;
  top: 80px;
  z-index: 1;
  padding: 4px;
  /* padding-top: 4px; */

  @-moz-document url-prefix() {
    width: -moz-available;
  }

  @media screen and (max-width: 1280px) {
    padding-bottom: 0;
    top: 76px;
    /* padding-top: 5px; */
  }
`;
const BandCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 45px;

  @media screen and (max-width: 767px) {
    gap: 1px;
  }
`;
const Img = styled.div`
  position: relative;
  top: 4px;
  @media screen and (max-width: 767px) {
    width: 74%;
  }
`;
const LgScreen = styled.div`
  @media screen and (max-width: 1280px) {
    position: relative;
  }
  @media screen and (max-width: 767px) {
    left: 25px;
    position: inherit;
  }
`;

export default function CesBlackRibbon() {
  const [more, setMore] = useState(false);
  const knowmore = () => {
    userTrack("", "Clicked on Know more from Banner", "Ideapoke website");
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/ces-2025.html";
  };
  return (
    <div>
      <BandWrap>
        <BandCenter>
          <Img>
            <Image src={Logo} alt="Logo" layout="intrinsic" />
          </Img>
          <div>
            <P
              text="Gear up for CES 2025"
              fontSize="30px"
              LgFontSize="27px"
              fontWeight="700"
              color="#fff"
              letterSpacing="0.5px"
              mobFontSize="14px"
              mobPosition="relative"
              mobRight="35px"
            ></P>
          </div>
          <LgScreen>
            <Button
              variant="solidWhite"
              text="Know more"
              width="108px"
              height="49px"
              // Lgheight="35px"
              onClick={knowmore}
            ></Button>
          </LgScreen>
        </BandCenter>
      </BandWrap>
    </div>
  );
}
