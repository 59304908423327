import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import ReactModal from "react-modal";
import "./Ces.css";
import { P } from "../CommonStyle";
import { userTrack } from "../Common";
import BgPop from "./images/popup-bg.svg";
import CESlogo from "./images/popup-logoevent.svg";
import Image from "next/image";
import LogoBg from "./images/cescta_combolog-bg.svg";

const Reject = styled.div`
  position: relative;
  width: 947px !important;
  min-height: 200px;
  background: #fff;
  margin: 0 auto;
  height: auto !important;
  overflow: hidden !important;
  border-radius: 14px;
  border: none;
  outline: none;
  margin-left: 0;
  margin-right: 0;
  display: grid;
  grid-template-columns: 0.5fr 1fr;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
    width: auto !important;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
`;
const TextC = styled.p`
  margin: 0;
  margin: 0 auto;
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  width: 475px;
  margin-top: 20px;
  @media screen and (max-width: 1280px) {
    margin-top: 10px;
  }
  @media screen and (max-width: 767px) {
    width: auto;
  }
`;
const TextC1 = styled.p`
  margin: 0;
  margin: 0 auto;
  color: #000;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 25px;
  margin-bottom: 15px;
  width: 480px;

  @media screen and (max-width: 1280px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 767px) {
    width: auto;
  }
`;
const ClickLink = styled.div`
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  background: #ff7624;
  margin-top: 20px;

  &:hover {
    color: #fff;
    background-color: #cd5900;
  }
  @media screen and (max-width: 767px) {
    font-size: 15px;
  }
`;
const PopupImage = styled.div`
  width: 350px;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const ImgLogo = styled.div`
  margin-top: 45px;
  /* width: 60%; */

  @media screen and (max-width: 767px) {
    margin-top: 25px;

    img {
      width: 200px; /* Adjusted width */
      height: 90px; /* Adjusted height */
    }
  }
`;

export default function CesEventPopup() {
  const [isOpen, setIsOpen] = useState(true);
  // useEffect(() => {
  //   // Check if the flag is present in localStorage
  //   const isModalClosed = localStorage.getItem("modalClosed");

  //   // If the flag is present, set isOpen to false
  //   if (isModalClosed) {
  //     setIsOpen(false);
  //   } else {
  //     setIsOpen(true);
  //   }
  // }, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount

  const handleClose = () => {
    //user track event
    userTrack("", "Onclick of close icon in CES event popup", "HomePage");
    // Set the flag in localStorage when the modal is closed
    localStorage.setItem("modalClosed2024", "true");
    setIsOpen(false);
  };

  const cesevent = () => {
    userTrack(
      `${localStorage.storedEmail}`,
      "Clicked on Clicked here to know more about CES Event",
      "Ideapoke website"
    );
    localStorage.setItem("modalClosed2024", "true");
    setIsOpen(false);
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/ces-2025.html";
  };
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }

    return () => {
      // Cleanup function to revert the style when the component unmounts
      document.body.style.overflow = "visible";
    };
  }, [isOpen]);
  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={() => handleClose()}
        ariaHideApp={false}
        className="modal-popup-back-ces"
      >
        <Reject>
          {/* <div> */}
          <PopupImage>
            <Image src={BgPop} width="400" height="599"></Image>
          </PopupImage>
          {/* </div> */}
          <div>
            <div className="closePopup" onClick={() => handleClose()}>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.01689 1.01689C1.34785 0.685932 1.79673 0.5 2.26478 0.5C2.73283 0.5 3.18171 0.685932 3.51267 1.01689L11 8.50422L18.4873 1.01689C18.8183 0.685932 19.2672 0.5 19.7352 0.5C20.2033 0.5 20.6521 0.685932 20.9831 1.01689C21.3141 1.34785 21.5 1.79673 21.5 2.26478C21.5 2.73283 21.3141 3.18171 20.9831 3.51267L13.4958 11L20.9831 18.4873C21.3141 18.8183 21.5 19.2672 21.5 19.7352C21.5 20.2033 21.3141 20.6521 20.9831 20.9831C20.6521 21.3141 20.2033 21.5 19.7352 21.5C19.2672 21.5 18.8183 21.3141 18.4873 20.9831L11 13.4958L3.51267 20.9831C3.18171 21.3141 2.73283 21.5 2.26478 21.5C1.79673 21.5 1.34785 21.3141 1.01689 20.9831C0.685931 20.6521 0.5 20.2033 0.5 19.7352C0.5 19.2672 0.685931 18.8183 1.01689 18.4873L8.50422 11L1.01689 3.51267C0.685931 3.18171 0.5 2.73283 0.5 2.26478C0.5 1.79673 0.685931 1.34785 1.01689 1.01689Z"
                  fill="#676767"
                />
              </svg>
            </div>
            <Content>
              <ImgLogo>
                <Image
                  src={CESlogo}
                  width="400"
                  height="150"
                  alt="CES Logo"
                ></Image>
              </ImgLogo>
              <P
                text="Gear up for CES 2025"
                mobMarginTop="20px"
                marginTop="30px"
                color="#454545"
                fontSize="55px"
                fontWeight="700"
                letterSpacing="-1.25px"
                mobFontSize="28px"
              ></P>
              <TextC>
                CES 2024 brought to the fore many breakthrough innovations and
                startups.
              </TextC>
              <TextC1>
                Learn what unfolded in this event last year and know what to
                expect in the 2025 event.
              </TextC1>
              <ClickLink onClick={cesevent}>
                Click here to know more about CES Event
              </ClickLink>
            </Content>
          </div>
        </Reject>
      </ReactModal>
    </div>
  );
}
